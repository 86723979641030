// @font-face {
//     font-family: "Flaticon";
//     src: url("../fonts/Flaticon.woff2") format("woff2");
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap; }
  
//   @media screen and (-webkit-min-device-pixel-ratio: 0) {
//     @font-face {
//       font-family: "Flaticon";
//       src: url("../fonts/Flaticon.svg#Flaticon") format("svg"); } }
  
//   [class^='flaticon-']:before,
//   [class*=' flaticon-']:before,
//   [class^='flaticon-']:after,
//   [class*=' flaticon-']:after {
//     font-family: Flaticon;
//     font-size: 20px;
//     font-style: normal;
//   }
  
//   .flaticon-angle-arrow-pointing-to-right:before {
//     content: '\f100';
//   }
  
//   .flaticon-plus:before {
//     content: '\f101';
//   }
  
//   .flaticon-right-arrow:before {
//     content: '\f102';
//   }
  
//   .flaticon-call:before {
//     content: '\f103';
//   }
  
//   .flaticon-left-arrow:before {
//     content: '\f104';
//   }
  
//   .flaticon-minus:before {
//     content: '\f105';
//   }
  
//   .flaticon-check:before {
//     content: '\f106';
//   }
  
//   .flaticon-telephone:before {
//     content: "\f103";
//   }
  
//   .flaticon-profile-user:before {
//     content: "\f100";
//   }
  
//   .flaticon-location:before {
//     content: "\f101";
//   }
  
//   .flaticon-magnifying-glass:before {
//     content: "\f102";
//   }
  
//   .flaticon-loading:before {
//     content: "\f103";
//   }
  
//   .flaticon-agent:before {
//     content: "\f104";
//   }
  
//   .flaticon-target:before {
//     content: "\f105";
//   }
  
//   .flaticon-telephone:before {
//     content: "\f106";
//   }
  
  .ldBar {
    position: relative;
  }
  
  *,
  ::after,
  ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    color: #2f3d48;
  }
  
  .img-fluid {
    max-width: 100%;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'PT Sans', sans-serif;
    text-transform: capitalize;
    appearance: none;
    margin: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  h1:focus,
  h2:focus,
  h3:focus,
  h4:focus,
  h5:focus,
  h6:focus {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: 0;
  }
  
  p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 31px;
  }
  
  ul {
    margin: 0;
    padding: 0;
  }
  ul li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  a {
    text-decoration: none;
  }
  .text-underline{
    text-decoration: underline;
  }
  
  .bglight {
    background-color: #fafafa;
  }
  
  .selector-for-some-widget {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  
  .section_holder {
    position: relative;
  }
  .section_holder .navbar {
    padding: 1rem 0;
    background-color: #1e2a78;
  }
  .section_holder .navbar .logo {
    text-align: center;
  }
  .logo-text{
    font-size: 1.5rem;
  }
  .footer-logo-text{
    font-size: 1.4rem;
  }
  .section_holder .navbar .logo a {
    font-family: 'PT Sans', sans-serif;
    font-size: 1.5rem;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .section_holder .navbar .logo a svg {
    width: 60px;
    margin-right: 10px;
    height: 60px;
  }
  .section_holder .navbar .logo a svg .headlogo {
    fill: #fff;
  }
  .section_holder header {
    background-color: #1e2a78;
    padding: 4rem 0 4rem 0;
    text-align: center;
  }
  .section_holder header h1 {
    color: #fff;
    line-height: 1.2;
  }
  .section_holder header h1 span {
    color: #f7c355;
  }
  .section_holder header h2 {
    color: #fff;
  }
  .section_holder header h3 {
    color: #fff;
    font-size: 24px;
  }
  .section_holder header p {
    color: #fff;
  }
  .section_holder header .d-flex {
    margin-top: 1rem;
  }
  .section_holder header .d-flex .px-4 {
    color: #fff;
  }
  .section_holder header form {
    width: 100%;
  }
  .section_holder header form .form-group {
    display: table;
    margin: auto;
    width: 100%;
  }
  .section_holder header form .form-group input[type='text'] {
    float: left;
    width: 48%;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid #d9d9d9;
    padding-left: 50px;
    background-size: 14px 20px;
    line-height: 1;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 0.5rem;
    apperance: none;
    outline: 0;
  }
  .section_holder header form .form-group button {
    margin-top: 0.5rem;
    font-family: 'PT Sans', sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
    float: right;
    width: 48%;
    background-color: #f7c355;
    border: none;
    border-radius: 10px;
    text-align: center;
    color: #1e2a78;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    -webkit-transition: all, 0.2s;
    transition: all, 0.2s;
    cursor: pointer;
    -webkit-animation: wiggle 3s linear infinite;
    animation: wiggle 3s linear infinite;
  }
  .section_holder header .tollfreeheader a {
    background-color: #f7c355;
    margin-top: 1rem;
    padding: 15px;
    -webkit-box-ordinal-group: none;
    -ms-flex-order: none;
    order: none;
    border-radius: 10px;
    text-align: center;
    -webkit-transition: all, 0.2s;
    transition: all, 0.2s;
    display: block;
    font-size: 24px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 800;
    color: #1e2a78;
  }
  .section_holder section {
    padding: 4rem 0;
  }
  .section_holder section.section1 {
    padding-top: 2rem;
    padding-bottom: 0;
  }
  .section_holder section.section1 .section1title {
    text-align: center;
  }
  .section_holder section.section1 .section1title h1 {
    text-transform: capitalize;
    font-size: 1.5rem;
    color: #1e2a78;
  }
  .section_holder section.section1 .partnerlogo {
    margin: 1rem 0;
    text-align: center;
  }
  .section_holder section.section2 {
    padding: 4rem 0;
  }
  .section_holder section.section2 .section2title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .section_holder section.section2 .section2title h1 {
    font-size: 2rem;
    line-height: 45px;
    color: #2f3d48;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .section_holder section.section2 .section2title h1 span {
    color: #1e2a78;
  }
  .section_holder section.section2 .sectionfeature {
    position: relative;
    display: block;
    background: #fff;
    overflow: hidden;
    padding: 58px 30px 51px;
    border-radius: 10px;
    -webkit-box-shadow: 0 20px 40px rgba(211, 220, 232, 0.75);
    box-shadow: 0 20px 40px rgba(211, 220, 232, 0.75);
    text-align: center;
    margin-bottom: 2rem;
  }
  .section_holder section.section2 .sectionfeature .icon-box {
    position: relative;
    display: inline-block;
    width: 148px;
    height: 143px;
    text-align: center;
    margin: 0px auto 24px auto;
  }
  .section_holder section.section2 .sectionfeature .icon-box img {
    max-width: 100%;
  }
  .section_holder section.section2 .sectionfeature h2 {
    position: relative;
    display: block;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #1e2a78;
  }
  .section_holder section.section3 {
    padding: 4rem 0;
  }
  .section_holder section.section3 .section1holder h1 {
    font-size: 2rem;
    line-height: 45px;
    color: #2f3d48;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .section_holder section.section3 .section1holder h1 span {
    color: #1e2a78;
  }
  .section_holder section.section4 .section2image {
    text-align: center;
  }
  .section_holder section.section4 .section2holder h1 {
    font-size: 2rem;
    line-height: 45px;
    color: #2f3d48;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .section_holder section.section4 .section2holder h1 span {
    color: #1e2a78;
  }
  .section_holder section.section4 .section2holder ul {
    margin: 1rem 0 0 0;
  }
  .section_holder section.section4 .section2holder ul li {
    font-size: 18px;
    margin-bottom: 1rem;
  }
  .section_holder section.section4 .section2holder ul li .features {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .section_holder section.section4 .section2holder ul li i {
    margin-right: 1rem;
  }
  .section_holder section.section4 .section2holder ul li i:before {
    color: #55a630;
    font-size: 1.4rem;
  }
  .section_holder section.testimonialholder {
    background: #e5ebf8;
  }
  .section_holder section.testimonialholder .testimonialheading h1 {
    font-size: 2rem;
    line-height: 45px;
    color: #1e2a78;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .section_holder section.testimonialholder .testimonialheading h1 span {
    color: #1e2a78;
  }
  .section_holder section.testimonialholder .trust_seal {
    text-align: center;
    margin-bottom: 2rem;
  }
  .section_holder section.testimonialholder .trust_seal img {
    margin: 1rem 0rem;
  }
  .section_holder section.testimonialholder .owl-item.center .item {
    opacity: 1 !important;
    -webkit-transform: scale3d(1, 1, 1) !important;
    transform: scale3d(1, 1, 1) !important;
  }
  .section_holder section.testimonialholder .item {
    margin-bottom: 20px;
    opacity: 0.2;
    -webkit-transform: scale3d(0.7, 0.7, 1);
    transform: scale3d(0.7, 0.7, 1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    max-width: 320px;
  }
  .section_holder section.testimonialholder .item .testmonial {
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0 5px 46px rgba(0, 0, 0, 0.1),
      0 15px 12px rgba(0, 0, 0, 0.02);
    box-shadow: 0 5px 46px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02);
    border: 1px solid #ededed;
    background-color: #fff;
    position: relative;
    text-align: left;
  }
  .section_holder
    section.testimonialholder
    .item
    .testmonial
    .testimonial_author {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-bottom: 0.75rem;
  }
  .section_holder
    section.testimonialholder
    .item
    .testmonial
    .testimonial_author
    .testimonial_image {
    max-width: 12.5%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
  }
  .section_holder
    section.testimonialholder
    .item
    .testmonial
    .testimonial_author
    .testimonial_image
    img {
    max-width: 100%;
    border-radius: 50%;
  }
  .section_holder
    section.testimonialholder
    .item
    .testmonial
    .testimonial_author
    .testimonial_author_name {
    max-width: 80%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    vertical-align: middle;
    margin: 0 auto;
  }
  .section_holder
    section.testimonialholder
    .item
    .testmonial
    .testimonial_author
    .testimonial_author_name
    h3 {
    position: relative;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .section_holder
    section.testimonialholder
    .item
    .testmonial
    .testimonial_author
    .testimonial_author_name
    .review
    .star-holder
    img {
    margin-top: 0.5rem;
    width: auto;
  }
  .section_holder
    section.testimonialholder
    .item
    .testmonial
    .testimonial_content
    p {
    line-height: 25px;
    font-size: 16px;
  }
  .section_holder
    section.testimonialholder
    .item
    .testmonial
    .testimonial_content
    span {
    color: #000;
    font-weight: 600;
    margin: 15px 0;
    display: inline-block;
  }
  .section_holder section.faq .faqtitle h1 {
    font-size: 2rem !important;
    line-height: 45px !important;
    color: #2f3d48;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .section_holder section.faq .faqtitle h1 span {
    color: #1e2a78;
  }
  .section_holder section.faq .faqs {
    margin-top: 1.5rem;
  }
  .section_holder section.faq .faqs .faq li.q {
    display: block;
    position: relative;
    padding: 20px 20px 20px 0;
    color: #1e2a78;
    background-color: transparent;
    border-color: #eee;
    border-bottom: 1px solid #eee;
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    cursor: pointer;
    text-transform: capitalize;
  }
  .section_holder section.faq .faqs .faq li.q i {
    position: absolute;
    right: 0;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    top: 25px;
  }
  .section_holder section.faq .faqs .faq li.q .flaticon-minus {
    display: none;
  }
  .section_holder section.faq .faqs .faq li.q.active .flaticon-plus {
    display: none !important;
  }
  .section_holder section.faq .faqs .faq li.q.active .flaticon-minus {
    display: block !important;
  }
  .section_holder section.faq .faqs .faq li.a {
    display: none;
    padding: 15px 15px 15px 0;
    font-family: open sans, sans-serif;
  }
  .section_holder section.section5 {
    background-color: #1e2a78;
    text-align: center;
  }
  .section_holder section.section5 h1 {
    margin: 1rem 0;
    font-size: 2rem;
    line-height: 1.375;
    color: #fff;
  }
  .section_holder section.section5 p {
    margin-bottom: 2rem;
    color: #fff;
  }
  .section_holder section.section5 .lastsectioncontent {
    margin: auto;
    text-align: center;
  }
  .section_holder section.section5 .lastsectioncontent .cta label {
    text-transform: capitalize;
    color: #fff;
    font-weight: 800;
    display: block;
    font-size: 1.2rem;
  }
  .section_holder section.section5 .lastsectioncontent .cta .formgroup {
    display: table;
    width: 100%;
    margin-bottom: 1rem;
  }
  .section_holder
    section.section5
    .lastsectioncontent
    .cta
    .formgroup
    input[type='text'] {
    float: left;
    width: 48% !important;
    padding-top: 17px;
    padding-bottom: 17px;
    border: 1px solid #d9d9d9;
    padding-left: 50px !important;
    background-size: 14px 20px;
    line-height: 1;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 0.5rem;
    outline: 0;
  }
  .section_holder section.section5 .lastsectioncontent .cta .formgroup button {
    margin-top: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
    float: right;
    width: 48% !important;
    background-color: #f7c355;
    border: none;
    border-radius: 10px;
    text-align: center;
    color: #1e2a78;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    -webkit-transition: all, 0.2s;
    transition: all, 0.2s;
    cursor: pointer;
  }
  .section_holder section.section5 .lastsectioncontent .cta .px-4 {
    color: #fff;
  }
  .section_holder section.section5 .lastsectioncontent .cta h3 {
    text-transform: capitalize;
    margin-top: 0.5rem;
    color: #fff;
    display: block;
    font-size: 1.2rem;
  }
  .section_holder section.section5 .lastsectioncontent .cta .phonenumber {
    margin-top: 0.5rem;
    font-family: 'PT Sans', sans-serif;
    display: block;
    padding: 20px;
    background-color: #f7c355;
    border: none;
    border-radius: 10px;
    text-align: center;
    color: #1e2a78;
    font-size: 1.4rem;
    font-weight: 800;
    line-height: 1;
    -webkit-transition: all, 0.2s;
    transition: all, 0.2s;
    -webkit-animation: wiggle 3s linear infinite;
    animation: wiggle 3s linear infinite;
  }
  .section_holder footer {
    padding: 4rem 0;
    background-color: #e5ebf8;
  }
  .section_holder footer .first-row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  .section_holder footer .last_row p {
    font-size: 13px;
    color: #4a5568;
  }
  .section_holder footer .footer-logo a {
    color: #1e2a78;
    text-decoration: none;
    font-family: 'PT Sans', sans-serif;
    font-weight: 800;
    font-size: 1.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: start;
  }
  .section_holder footer .footer-logo a img {
    width: 60px;
    margin-right: 10px;
    height: 60px;
  }
  .section_holder footer .footer-logo p {
    margin-top: 1rem;
    line-height: 1;
    text-align: center;
  }
  .section_holder footer .footer_nav ul {
    margin: 10px 0 0 0;
    padding: 0;
  }
  .section_holder footer .footer_nav ul li {
    display: block;
    text-align: center;
  }
  .section_holder footer .footer_nav ul li a {
    color: #1e2a78;
    text-transform: uppercase;
    margin: 0 1rem;
  }
  .section_holder .callbutton {
    position: fixed;
    right: 1em;
    bottom: 2%;
    z-index: 900;
    -webkit-animation: e 3s linear infinite;
    animation: e 3s linear infinite;
    background: #f7c355;
    border-radius: 50%;
    -webkit-box-shadow: 5px 6px 9px 0 rgba(60, 60, 60, 0.5);
    box-shadow: 5px 6px 9px 0 rgba(60, 60, 60, 0.5);
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 34px;
  }
  .section_holder .callbutton i:before {
    font-size: 34px;
    color: #1e2a78;
  }
  
  @media (min-width: 575.99px) {
    p {
      font-size: 16px;
    }
    ul li {
      font-size: 16px !important;
    }
    .section_holder .navbar {
      background-color: #fff;
    }
    .section_holder .navbar .logo a {
      color: #1e2a78;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .section_holder .navbar .logo a svg .headlogo {
      fill: #1e2a78;
    }
    .section_holder .navbar .tollfree {
      float: right;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-align: right;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .section_holder .navbar .tollfree .tollfree-flex1 {
      font-size: 26px;
      margin-right: 15px;
    }
    .section_holder .navbar .tollfree .tollfreetext {
      color: #2f3d48;
      font-size: 14px;
      line-height: 14px;
    }
    .section_holder .navbar .tollfree .tollfreetext p {
      font-size: 13px !important;
      line-height: 1;
      margin: 0;
    }
    .section_holder .navbar .tollfree .tollfreenumber {
      color: #1e2a78;
      font-size: 24px;
      font-family: 'PT Sans', sans-serif;
      font-weight: 800;
      line-height: 1.3;
      margin: 0;
    }
    .section_holder .navbar .tollfree .tollfreetext2 {
      color: #2f3d48;
      font-size: 13px;
      line-height: 10px;
    }
    .section_holder .navbar .tollfree .tollfreetext2 p {
      margin: 0;
      line-height: 1;
    }
    .section_holder .navbar .tollfree .tollfreetext2 span {
      width: 8px;
      height: 8px;
      display: inline-block;
      background: #11980b;
      border-radius: 50%;
    }
    .section_holder header {
      background-color: transparent;
      padding-top: 2rem;
      padding-bottom: 0;
    }
    .section_holder header .herosection {
      position: relative;
    }
    .section_holder header .herosection .herosectionimage {
      max-width: 100%;
      position: relative;
      z-index: -1;
      min-height: 625px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-image: url('../image/siarhei-plashchynski-4ptfb2wMP4M-unsplash.jpg');
    }
    .section_holder header .herosection .herosectionholder {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0%;
    }
    .section_holder header .herosection .herosectionholder .herosectionleft {
      max-width: 90% !important;
      padding: 40px;
      background-color: #1e2a78;
      height: calc(100% - 10%);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      position: absolute;
    }
    .section_holder
      header
      .herosection
      .herosectionholder
      .herosectionleft
      .d-flex {
      margin: 0.5rem !important;
    }
    .section_holder section {
      text-align: center;
    }
    .section_holder section.section4 .section2holder ul {
      text-align: left;
    }
    .section_holder section.section5 h1 {
      font-size: 3rem;
    }
    .section_holder section.testimonialholder .item {
      max-width: 500px;
    }
  }
  
  .site_wrapper {
    min-height: 100vh;
  }
  .site_wrapper header {
    padding: 1rem 0;
    border-bottom: 1px solid #e0e0e0;
  }
  .site_wrapper header .number {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: none;
  }
  
  .thanks_page_wrapper{
    background-color: #f5fafd !important;
  }
  
  .site_wrapper header .number .header-flex1 {
    font-size: 26px;
    margin-right: 15px;
  }
  .site_wrapper header .number .header-flex1 .htext {
    color: #4a5568;
    font-size: 14px;
    line-height: 14px;
  }
  .site_wrapper header .number .header-flex1 .hnumber {
    color: #1e2a78;
    font-size: 24px;
    font-family: "PT Sans";
    font-weight: 800;
    line-height: 1.3;
  }
  .site_wrapper header .number .header-flex1 .htext2 {
    color: #1e2a78;
    font-size: 13px;
    line-height: 10px;
  }
  .site_wrapper header .number .header-flex1 .htext2 span {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: #11980b;
    border-radius: 50%;
  }
  
  .site_wrapper header .logo a {
    color: #1e2a78;
    text-decoration: none;
    font-family: 'PT Sans';
    font-weight: 800;
    font-size: 1.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1;
  }
  .site_wrapper header .logo a img {
    width: 60px;
    margin-right: 10px;
    height: 60px;
  }
  
  .site_wrapper .formheadline {
    padding: 5rem 0 0;
    background-image: url('../image/header-banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .site_wrapper .formheadline h1 {
    font-size: 33px;
    color: #fff;
    padding: 0;
    margin: 0 0 20px;
    line-height: normal;
    text-align: center;
    font-weight: 400;
  }
  .site_wrapper .formheadline .progress-wrapper {
    margin-bottom: -50px;
  }
  
  .site_wrapper .formheadline .progress-wrapper .progress .progress-bar{
    width: 120px;
    position: relative;
    top: 0;
  }
  .site_wrapper .formheadline #ldBar {
    text-align: center;
    margin: 0 auto;
    background-color: #fff;
    font-size: 20px;
    position: relative;
    padding: 0;
    width: 6em !important;
    height: 6em !important;
    border-radius: 50%;
    line-height: 5em;
  }
  .site_wrapper .formheadline .start-label {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: #1e2a78;
    line-height: 28px;
    font-weight: 700;
  }
  
  .site_wrapper .form_holder {
    padding: 0 0 5rem;
    background: #fff;
  }
  .site_wrapper .form_holder .form_container {
    max-width: 532px;
    margin: 4rem auto 0 auto;
  }
  .site_wrapper .form_holder .form_container .steps {
    text-align: left;
  }
  .site_wrapper .form_holder .form_container .steps {
    text-align: left;
  }
  .site_wrapper .form_holder .form_container form .steps h3.current {
    display: block;
    text-align: center;
  }
  .site_wrapper .form_holder .form_container .steps h3 {
    font-size: 26px;
    line-height: 44px;
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    text-transform: capitalize;
    margin: 20px 0;
    margin-top: 0 !important;
    color: #1e2a78;
  }
  .site_wrapper .form_holder .form_container .step-wrapper {
    width: 100%;
    overflow: hidden;
  }
  .site_wrapper .form_holder .form_container .steps .form-step {
    margin-top: 1rem;
    background: transparent;
  }
  
  .custominput {
    height: 50px;
    font-size: 16px;
    border-radius: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #d3e0e8;
    padding: 12px 14px;
    display: block;
    width: 100%;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    margin: auto !important;
  }
  
  .site_wrapper .form_holder .form_container .steps .actions {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    gap: 1rem;
    padding: 0 1rem;
  }
  
  .site_wrapper .form_holder .actions a {
    font-size: 22px;
    font-weight: 700;
    color: #1c4067;
    text-decoration: none;
    text-align: center;
    width: 100%;
  }
  
  .site_wrapper
    .form_holder
    .form_container
    .steps
    .name-wrapper
    .input-group
    input {
    max-width: 90%;
  }
  
  .site_wrapper .form_holder .form_container .steps .firstnameholder,
  .site_wrapper .form_holder .form_container .steps .lastnameholder {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .site_wrapper .form_holder .form_container .steps .form_option .option a {
    border: 1px solid #d3e0e8;
    border-radius: 6px !important;
    background-color: #fff;
    color: #4a5568;
    width: 100%;
    display: block;
    text-align: center;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 8px 14px 9px;
    margin-bottom: 0.8rem;
    white-space: normal;
    font-weight: 300;
    cursor: pointer;
  }
  .site_wrapper .form_holder .form_container .steps .form_option .option a:hover {
    background-color: #1e2a78;
    color: #e5ebf8;
    transition: 0.2s all;
  }
  
  .site_wrapper .form_holder .form_container .steps .unsure_btn_container {
    width: 100%;
    text-align: center;
  }
  .site_wrapper
    .form_holder
    .form_container
    .steps
    .unsure_btn_container
    .unsure_btn {
    font-size: 16px;
    font-weight: 300;
    color: #4a5568;
    outline: none;
    border: none;
    background: none;
  }
  .site_wrapper .form_holder .form_container .steps .address-first {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  .site_wrapper .form_holder .form_container .steps .address-first .stadd {
    max-width: 100%;
  
    flex: 0 100%;
    margin-right: 0px;
  }
  .zippost{
    flex: 1;
    padding-right: 0.7rem;
  }
  
  .site_wrapper .form_holder .form_container .steps .address-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .site_wrapper .form_holder .form_container .steps .address-two .form-group {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 100%;
    flex: 0 100%;
    margin-right: 0px;
  }
  
  select {
    height: 50px;
    font-size: 16px;
    border-radius: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #d3e0e8;
    padding: 12px 14px;
    display: block;
    width: 100%;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    margin: auto !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  }
  
  .site_wrapper .form_holder .form_container .steps .input-button {
    display: block;
    position: relative;
    margin: 1rem 0;
    width: 100%;
  }
  
  .site_wrapper .form_holder .form_container .steps .input-button a {
    margin-top: 0.5rem;
    font-family: "PT Sans";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    background-color: #1e2a78;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    -webkit-transition: all, 0.2s;
    transition: all, 0.2s;
    cursor: pointer;
  }
  
  .terms {
    color: #333333;
    display: block;
    font-size: 0.75rem;
    font-weight: 400 !important;
    line-height: 1.25rem;
    max-width: 50rem;
    padding-top: 1rem;
    text-align: justify;
    margin: 1rem auto 0px;
  }
  @media (min-width: 575.99px){
  
    .site_wrapper .form_holder .form_container .steps .name-wrapper {
      display: flex;
    }
    
    .site_wrapper .form_holder .form_container .steps .address-two .form-group {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-right: 10px;
    }
  }
  .site_wrapper .form_holder .loader h3 {
    font-size: 26px;
    line-height: 44px;
    display: block;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    text-transform: capitalize;
    margin: 20px 0;
    color: #1e2a78;
  }
  
  .site_wrapper .form_holder .loader .sections {
    display: table;
    width: 100%;
    text-align: center;
    transition: all .2s;
    opacity: 1;
  }
  
  .site_wrapper .form_holder .loader .sections p {
    font-size: 1.2rem;
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  .site_wrapper .form_holder .loader .sections i {
    display: inline-block;
    margin-right: 10px;
  }
  
  .loader h3 {
    font-size: 26px;
    line-height: 44px;
  }
  
  .site_wrapper .form_holder .loader .sections {
    display: table;
    width: 100%;
    text-align: center;
    visibility: hidden;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    opacity: 0;
  }
  
  /* thanks css */
  
  .site_wrapper .thank-you {
    display: table;
    width: 100%;
    height: 100%;
  }
  
  .site_wrapper .thank-you .thankyou {
    padding: 5rem 0;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  
  .site_wrapper .thank-you .thankyou h1 {
    font-size: 1.5rem !important;
    line-height: 1.2;
  }
  .site_wrapper .thank-you .thankyou .thankyoucallnumber {
    background: #fff;
    padding: 20px;
    margin: 20px auto;
    border-radius: 10px;
    max-width: 550px;
    border: 1px solid #d3e0e8;
    -webkit-box-shadow: 0 2px 3px 0 rgba(173, 191, 204, 0.333);
    box-shadow: 0 2px 3px 0 rgba(173, 191, 204, 0.333);
  }
  
  .site_wrapper .thank-you .thankyou .call-agent img {
    max-width: 25%;
  }
  .site_wrapper .thank-you .thankyou .thankyoucallnumber .due-to-para{
    font-size: 16px !important;
  }
  .site_wrapper .thank-you .thankyou .thankyoucallnumber #call-timer {
    color: #FF0004;
  }
  
  .site_wrapper .thank-you .thankyou .thankyoucallnumber a {
    margin-top: 0.5rem;
    font-family: "PT Sans";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    background-color: #1e2a78;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    -webkit-transition: all, 0.2s;
    transition: all, 0.2s;
    cursor: pointer;
    -webkit-animation: wiggle 3s linear infinite;
    animation: wiggle 3s linear infinite;
  }
  
  
  .continue-btn{
    margin-top: 0.5rem;
    font-family: 'PT Sans';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    background-color: #f7c355;
    border: none;
    border-radius: 10px;
    color: #1e2a78;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    -webkit-transition: all, 0.2s;
    transition: all, 0.2s;
    cursor: pointer;
  }
  .error-message{
    color: #1e2a78;
  }
  
  
  .terms_privacy_container{
    padding: 4rem 0;
  }
  .a-text-color{
    color: #1e2a78;
  }
  
  .insurance-partners {
    font-size: 16px;
    list-style: none;
    columns: auto 3;
    margin-top: 2rem;
  }
  .insurance-partners li {
    position: relative;
    padding-left: 15px;
  }
  .insurance-partners li:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 8px;
  }
  @media (min-width: 575.99px){
  ul li {
      font-size: 16px !important;
  }
  }
  @media (min-width: 575.99px){
  
    .site_wrapper header .number {
      display: block;
    }
  }
  @media (min-width: 575.99px) {
    .site_wrapper .form_holder .form_container .steps .address-first .stadd {
      max-width: 65%;
      flex: 0 65%;
      margin-right: 10px;
    }
  }
  @media (min-width: 575.99px) {
    .site_wrapper .form_holder .form_container .steps .form-step {
      background: #f5fafd;
      padding: 25px;
      -webkit-box-shadow: 0 2px 3px 0 rgba(173, 191, 204, 0.33);
      box-shadow: 0 2px 3px 0 rgba(173, 191, 204, 0.33);
      border-collapse: separate;
      border-radius: 6px;
      border: 1px solid #d3e0e8;
    }
  }
  
  @media (min-width: 767.99px) {
    .section_holder header .herosection .herosectionholder .herosectionleft {
      height: calc(100% - 40%);
    }
    .section_holder header .herosection .herosectionholder .herosectionleft h1 {
      text-align: left;
    }
    .section_holder header .herosection .herosectionholder .herosectionleft h2 {
      text-align: left;
    }
    .section_holder header .herosection .herosectionholder .herosectionleft p {
      text-align: left;
    }
    .section_holder header .herosection .herosectionholder .herosectionleft form {
      max-width: 85%;
      margin: auto;
    }
    .section_holder section {
      text-align: left;
    }
    .section_holder section.section1 .section1title {
      text-align: left;
    }
    .section_holder section.section2 .section2title h1 {
      font-size: 2.5rem;
    }
    .section_holder section.section3 .section1holder h1 {
      font-size: 2.5rem;
    }
    .section_holder section.testimonialholder .testimonialheading h1 {
      text-align: center;
    }
    .section_holder section.testimonialholder .testimonialheading p {
      text-align: center;
    }
    .section_holder section.faq .faqtitle h1 {
      text-align: center;
    }
    .section_holder section.faq .faqtitle p {
      text-align: center;
    }
  }
  
  @media (min-width: 991.99px) {
    p {
      font-size: 17px;
    }
    .section_holder .navbar .logo {
      text-align: left;
    }
    .section_holder .navbar .logo  a img{
      width: 60px;
      margin-right: 10px;
      height: 60px;
    }
    .section_holder header .herosection .herosectionholder .herosectionleft {
      width: 60%;
      left: -5%;
      right: auto;
    }
    .section_holder header .herosection .herosectionholder .herosectionleft h1 {
      font-size: 1.7rem;
    }
    .section_holder section.section1 .logoholders {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0 !important;
    }
    .section_holder section.section2 {
      padding-top: 2rem;
    }
    .section_holder section.section2 .section2title h1 {
      font-size: 2.5rem;
      line-height: 1.375;
    }
    .section_holder section.section2 .section2title p {
      margin-bottom: 2rem;
    }
    .section_holder section.section3 .section1title h1 {
      font-size: 1.3rem;
      text-align: left;
    }
    .section_holder section.section3 .section1holder h1 {
      font-size: 2.5rem;
      line-height: 1.375;
    }
    .section_holder section.section4 .section2holder h1 {
      font-size: 2.5rem;
      line-height: 1.375;
    }
    .section_holder section.testimonialholder .testimonialheading h1 {
      font-size: 2.5rem;
      line-height: 1.375;
    }
    .section_holder section.faq .faqtitle h1 {
      font-size: 2.5rem;
      line-height: 1.375;
    }
    .section_holder section.section5 {
      padding: 4rem 0;
      position: relative;
      background-color: #1e2a78;
      text-align: left;
    }
    .section_holder section.section5 .section5bg {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../image/plan-old-granny.jpg');
    }
  
    .section_holder footer .footer-logo p {
      text-align: left;
    }
    .section_holder footer .footer_nav ul {
      margin: 0;
    }
    .section_holder footer .footer_nav ul li {
      display: inline-block;
      text-align: left;
    }
  }
  
  @media (min-width: 1199.99px) {
    .section_holder header .herosection .herosectionholder .herosectionleft {
      width: 50%;
    }
    .section_holder header .herosection .herosectionholder .herosectionleft h1 {
      font-size: 2rem;
    }
  }
  
  @-webkit-keyframes e {
    0% {
      -webkit-transform: rotate(0) scale(1);
      transform: rotate(0) scale(1);
    }
    2% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    4% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    6% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    8% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    10% {
      -webkit-transform: rotate(-5deg) scale(0.95);
      transform: rotate(-5deg) scale(0.95);
    }
    12% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    14% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    16% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    18% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    20% {
      -webkit-transform: rotate(5deg) scale(1.1);
      transform: rotate(5deg) scale(1.1);
    }
    22% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    24% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    26% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    28% {
      -webkit-transform: rotate(0) scale(1);
      transform: rotate(0) scale(1);
    }
    to {
      -webkit-transform: rotate(0) scale(1);
      transform: rotate(0) scale(1);
    }
  }
  
  @keyframes e {
    0% {
      -webkit-transform: rotate(0) scale(1);
      transform: rotate(0) scale(1);
    }
    2% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    4% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    6% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    8% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    10% {
      -webkit-transform: rotate(-5deg) scale(0.95);
      transform: rotate(-5deg) scale(0.95);
    }
    12% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    14% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    16% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    18% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    20% {
      -webkit-transform: rotate(5deg) scale(1.1);
      transform: rotate(5deg) scale(1.1);
    }
    22% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    24% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    26% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    28% {
      -webkit-transform: rotate(0) scale(1);
      transform: rotate(0) scale(1);
    }
    to {
      -webkit-transform: rotate(0) scale(1);
      transform: rotate(0) scale(1);
    }
  }
  
  @-webkit-keyframes wiggle {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    80% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    85% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    95% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  
  @keyframes wiggle {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    80% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    85% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    95% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  /*# sourceMappingURL=main.css.map */
  